<template>
  <div class="right ">
    <div class="card">
      <div>
        <div class="flex">
          <span class="label is-bold">Endorsement</span>
        </div>
        <div v-if="!isSave">
          <v-button
            class="button-theme button-theme-padding-13"
            @click="handlerCancel"
          > {{isNext?'Back':'Cancel'}}</v-button>
          <v-button
            v-if="!isNext&&!isLock"
            class="button-blue button-theme-padding-13"
            @click="handlerNext"
          >Next</v-button>
          <v-button
            v-if="isNext&&isLock"
            class="button-blue  button-theme-padding-13"
            @click="handlerConfirm">
            <span class="flex-center ">
              <span>Confirm</span>
            </span>
          </v-button>
          <v-button
            v-if="isNext&&!isLock"
            class="button-blue  button-theme-padding-13"
            @click="handlerNext">
            <span class="flex-center ">
              <img
                src="../../../assets/images/wallet.svg"
                alt="">
              <span>Set Payment</span>
            </span>
          </v-button>
        </div>
        <div v-else>
          <v-button
            class="button-blue button-theme-padding-13"
            @click="handlerCancel"
          >Cancel</v-button>
          <v-button
            class="button-blue button-theme-padding-13"
            @click="handlerNext"
          >Save</v-button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'endorsementDialog',
  props:{
    isNext:{
      type: Boolean,
      default:false
    },
    isLock:{
      type: Boolean,
      default:false
    },
    isSave: {
      type: Boolean,
      default:false
    }
  },
  methods:{
    ...mapActions('coverage', ['setDomesticPremium','setHomePremium']),

    handlerCancel(){
      this.$router.back()
      this.$emit('handlerCancel')
    },
    handlerNext(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$emit('handlerNext')
    },
    handlerConfirm(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$emit('handlerConfirm')
    },
  }
}
</script>

<style scoped lang="scss">
.right {
  //margin-top: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  .card {
    background: white;
    border-radius: 5px;
    padding: 15px;
  }
  .button-theme{
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .button-blue{
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .is-bold{
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    margin-bottom: 20px;
  }
  .flex-center{
    align-items: center;
    justify-content: center;
    img{
      margin-right: 5px;
    }
  }
}
</style>