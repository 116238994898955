<template>
  <section>
    <div class="table">
      <div class="th">
        <span>Cancellation</span>
      </div>
      <div
        class="tr">
        <span>Policy Period</span>
        <span>
          {{showHkDate(form.effective_date)+' to '+showHkDate(form.expiry_date)}}
          <!--          {{showHkDate(form.endorsement_effective_date)}}-->
        </span>
      </div>
      <div
        class="tr">
        <span>
          Cancellation Effective Date
        </span>
        <span>{{showHkDate(form.cancellation_effective_date)}}</span>
      </div>
      <div class="tr">
        <span>Cancellation Way</span>
        <span>
          {{getOptionName('wayOptions',premium.cancellation_way_type)}}
        </span>
      </div>
      <div class="th">
        <span>Cancellation Premium</span>
      </div>
    </div>
    <transaction
      :form="form"
      :isMonth="false"
      @isRefresh="refresh"></transaction>
    <el-row>
      <v-row-col
        v-if="!isInfo"
        :same="true"
        :same-number="24">
        <div class="flex-justify flex block-box">
          <div class="flex-cloumn">
            <div class="flex">
              <span>Referrer Settle Date ：</span>
              <!--              <span>{{getOptionName('globalOptions.hire_purchase',hirePurchaseOwner)}}</span>-->
            </div>
            <div class="flex">
              <span>Remark ：</span>
              <!--              <span>{{getOptionName('globalOptions.hire_purchase',hirePurchaseOwner)}}</span>-->
            </div>
          </div>
          <div class="flex-cloumn">
            <div class="flex">
              <span>Sent Date：{{showHkDate('')}}</span>
            </div>
            <div class="flex">
              <span>Remark ：</span>
              <!--              <span>{{getOptionName('globalOptions.hire_purchase',hirePurchaseOwner)}}</span>-->
            </div>
          </div>
        </div>
      </v-row-col>
    </el-row>
    <el-row :gutter="20">
      <v-row-col>
        <el-row :gutter="20">
          <v-row-col
            :same="true"
            :same-number="24">
            <div
              class="table">
              <div></div>
              <div
                class="tr">
                <span>Cancellation Effective Date</span>
                <span>
                  {{showHkDate(form.cancellation_effective_date)}}
                </span>
              </div>
              <div
                class="tr">
                <span>Refund Premium</span>
                <span>
                  {{premium.refund_premium|formatMoney(2,true)}}
                </span>
              </div>
              <div
                class="tr">
                <span>{{refundName}}</span>
                <span>
                  {{premium.refund_ia_mib_ec|formatMoney(2,true)}}
                </span>
              </div>
              <div
                class="tr">
                <span>Total</span>
                <span>
                  {{premium.refund_total|formatMoney(2,true)}}
                </span>
              </div>
            </div>
          </v-row-col>

          <v-row-col
            :same="true"
            :same-number="24">
            <div class="table">
              <div >
                <div></div>
                <div
                  class="tr">
                  <span>Special Discount %</span>
                  <span>
                    {{premium.special_discount}} %
                  </span>
                </div>
                <div
                  class="tr">
                  <span>Refund Premium</span>
                  <span>
                    {{premium.refund_special_premium|formatMoney(2,true)}}
                  </span>
                </div>
                <div
                  class="tr">
                  <span>{{refundName}}</span>
                  <span>
                    {{premium.refund_special_ia_mib_ec|formatMoney(2,true)}}

                  </span>
                </div>
                <div
                  class="tr">
                  <span>Total</span>
                  <span>
                    {{premium.refund_special_total|formatMoney(2,true)}}

                  </span>
                </div>
              </div>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="table">
              <div></div>
              <div
                class="tr">
                <span>Net %</span>
                <span>
                  {{premium.net}} %
                </span>
              </div>
              <div
                class="tr">
                <span>Refund Premium</span>
                <span>
                  {{premium.refund_net_premium|formatMoney(2,true)}}
                </span>
              </div>
              <div
                class="tr">
                <span>{{refundName}}</span>
                <span>
                  {{premium.refund_net_ia_mib_ec|formatMoney(2,true)}}

                </span>
              </div>
              <div
                class="tr">
                <span>Total</span>
                <span>
                  {{premium.refund_net_total|formatMoney(2,true)}}

                </span>
              </div>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="table">
              <div >
                <div></div>
                <div
                  class="tr">
                  <span>Insurer Net %</span>
                  <span>
                    {{premium.insurer_net}} %
                  </span>
                </div>
                <div
                  class="tr">
                  <span>Refund Premium</span>
                  <span>
                    {{premium.refund_insurer_net_premium|formatMoney(2,true)}}
                  </span>
                </div>
                <div
                  class="tr">
                  <span>{{refundName}}</span>
                  <span>
                    {{premium.refund_insurer_net_ia_mib_ec|formatMoney(2,true)}}

                  </span>
                </div>
                <div
                  class="tr">
                  <span>Total</span>
                  <span>
                    {{premium.refund_insurer_net_total|formatMoney(2,true)}}

                  </span>
                </div>
              </div>
            </div>
          </v-row-col>
        </el-row>
      </v-row-col>
      <v-row-col>
        <el-row>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="table">
              <div></div>
              <div
                class="tr">
                <span>Original Annual Gross Premium</span>
                <span>
                  {{premium.original_gross_premium|formatMoney(2,true)}}

                </span>
              </div>
              <div
                class="tr">
                <span>Original Annual Premium</span>
                <span>
                  {{premium.original_premium|formatMoney(2,true)}}

                </span>
              </div>
              <div
                class="tr">
                <span>Original Annual IA Levy MIB</span>
                <span>
                  {{premium.original_ia_mib_ec|formatMoney(2,true)}}
                </span>
              </div>
            </div>
          </v-row-col>
        </el-row>
      </v-row-col>
    </el-row>

  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
import transaction from '@/views/components/info/transcation'

export default {
  name: 'cancelPremium',
  components:{
    transaction
  },
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isInfo:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    premium(){
      return this.form.premium||{}
    },
    productId(){
      return this.form.product_id||''
    },
    showEcHKD(){
      return [13,14,15,16,17,18].includes(this.productId)
    },
    refundName(){
      if(this.showEcHKD)
        return  'Refund IA Levy & EC Levy'
      else if(this.productId==10)
        return  'Refund IA Levy & MIB  '
      else return 'Refund IA Levy'
    },
  },
  methods:{
    refresh(){
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped lang="scss">
.table{
  border: 1px solid #CBD5E0;
  .cloumn_tr{
    flex-direction: column;
  }

  .margin-bottom{
    margin-bottom: 20px;
  }
  .flex-cloumn{
    display: flex;
    flex-direction: column;
    .flex{
      margin-bottom: 20px;
    }
  }
}
.block-box{
  background: #EDF2F7;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  .flex{
    span:nth-child(1){
      font-weight: bold;
      font-size: 14px;
      color: #718096;
    }
    span:nth-child(2){
      font-size: 14px;
      color: #718096;
    }
  }
}
</style>