<template>
  <section class="select-payment">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      :disabled="isLock"
    >
      <div
        class="payment-content hand"
        :class="{'check-bload':formData.type===2,'is-payment':isLock}"
        @click="handlerClear(2)">
        <span class="check-flex">
          <span
            class="checked-box"
            :class="{'is-checked':formData.type==2}"

          ></span>
        </span>
        <div class="payment-body">
          <div class="flex">
            <span class="title-bold">
              Cheque
            </span>
          </div>
          <el-collapse-transition >
            <div v-if="formData.type==2">
              <el-row

                :gutter="20"
                justify="space-around"
              >
                <v-row-col>
                  <div class="form-item">
                    <span>Date</span>
                    <el-form-item>
                      <v-date-picker
                        v-model="formData.date"
                        :disabled="isPayment"></v-date-picker>
                    </el-form-item>
                  </div>
                </v-row-col>
                <v-row-col>
                  <div class="form-item">
                    <span>Handler Name</span>
                    <el-form-item >
                      <v-input
                        v-model="formData.handle_name"
                        :disabled="isPayment"></v-input>
                    </el-form-item>
                  </div>
                </v-row-col>
                <v-row-col>
                  <div class="form-item">
                    <span>Cheque No.</span>
                    <el-form-item >
                      <v-input
                        v-model="formData.cheque_no"
                        :disabled="isPayment"></v-input>
                    </el-form-item>
                  </div>
                </v-row-col>
                <v-row-col>
                  <div class="form-item">
                    <span>Cheque Owner</span>
                    <el-form-item >
                      <v-input
                        v-model="formData.cheque_owner "
                        :disabled="isPayment"></v-input>
                    </el-form-item>
                  </div>
                </v-row-col>
                <v-row-col>
                  <div class="form-item">
                    <span>Cheque</span>
                    <el-form-item>
                      <file-container
                        :edit="isPayment?false:true"
                        :disabled="isPayment"
                        :fileData="{
                          url: formData.cheque_file_url,
                          client_name: formData.cheque_client_name
                        }"
                        :otherData="{
                          idKey: 'cheque_file_id',
                          urlKey: 'cheque_file_url',
                          client_name:'cheque_client_name'
                        }"
                        @delete="handlerDeleteFile"
                        @uploadSuccess="handlerUploadSuccess" />
                    </el-form-item>
                  </div>
                </v-row-col>
              </el-row>
              <div align="center" >
                <v-button
                  class="button-blue"
                  :class="isAble?'disable-class':''"
                  :disabled="isAble"
                  @click="hanlderProve">Approve Cheque</v-button>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
      <div
        class="payment-content hand"
        :class="{'check-bload':formData.type===3,'is-payment':isPayment||isLock}"
        @click="handlerClear(3)">
        <div class="check-flex">
          <span
            class="checked-box"
            :class="formData.type==3?'is-checked':''"

          ></span>
        </div>
        <div class="payment-body">
          <div class="flex">
            <span class="title-bold">
              Cash
            </span>
          </div>
          <el-collapse-transition>
            <el-row
              v-if="formData.type==3"
              :gutter="20"
              justify="space-around"
            >
              <v-row-col>
                <div class="form-item">
                  <span>Date</span>
                  <el-form-item>
                    <v-date-picker v-model="formData.date"></v-date-picker>
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col>
                <div class="form-item">
                  <span>Handler Name</span>
                  <el-form-item prop="handle_name">
                    <v-input v-model="formData.handle_name"></v-input>
                  </el-form-item>
                </div>
              </v-row-col>
            </el-row>
          </el-collapse-transition>
        </div>
      </div>
      <div
        class="payment-content hand"
        :class="{'check-bload':formData.type===4,'is-payment':isPayment||isLock}"
        @click="handlerClear(4)">
        <div class="check-flex">
          <span
            class="checked-box"
            :class="formData.type==4?'is-checked':''"

          ></span>
        </div>
        <div class="payment-body">
          <div class="flex">
            <span class="title-bold">
              ATM
            </span>
          </div>
          <el-collapse-transition>
            <el-row
              v-if="formData.type==4"
              :gutter="20"
              justify="space-around"
            >
              <v-row-col>
                <div class="form-item">
                  <span>Date</span>
                  <el-form-item prop="date">
                    <v-date-picker v-model="formData.date"></v-date-picker>
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col>
                <div class="form-item">
                  <span>Handler Name</span>
                  <el-form-item >
                    <v-input v-model="formData.handle_name"></v-input>
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col>
                <div class="form-item">
                  <span>Bank No.</span>
                  <el-form-item>
                    <v-select
                      v-model="formData.bank_no_id"
                      :list="globalOptions.banks"></v-select>
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col>
                <div class="form-item">
                  <span>Account No.</span>
                  <el-form-item >
                    <v-input v-model="formData.account_no"></v-input>
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col>
                <span>Receipt</span>
                <el-form-item >
                  <file-container
                    :edit="true"
                    :fileData="{
                      url: formData.receipt_file_url,
                      client_name: formData.receipt_client_name
                    }"
                    :otherData="{
                      idKey: 'receipt_file_id',
                      urlKey: 'receipt_file_url',
                      client_name:'receipt_client_name'
                    }"
                    @delete="handlerDeleteFile"
                    @uploadSuccess="handlerUploadSuccess" />
                </el-form-item>
              </v-row-col>
            </el-row>
          </el-collapse-transition>
        </div>
      </div>
      <!--             paid on account-->
      <div
        v-if="!isUpdatePayment"
        class="payment-content hand"
        :class="{'check-bload':formData.type===6,'is-payment':isPayment||isLock}"
        @click="handlerClear(6)">
        <div class="check-flex">
          <span
            class="checked-box"
            :class="formData.type==6?'is-checked':''"

          ></span>
        </div>
        <div class="payment-body">
          <div class="flex">
            <span class="title-bold">
              Paid on account
            </span>
          </div>
          <el-collapse-transition>
            <el-row
              v-if="formData.type==6"
              :gutter="20"
              justify="space-around"
            >
              <v-row-col>
                <div class="form-item">
                  <span>Date</span>
                  <el-form-item prop="date">
                    <v-date-picker v-model="formData.date"></v-date-picker>
                  </el-form-item>
                </div>
              </v-row-col>
              <v-row-col>
                <div class="form-item">
                  <span>Handler Name</span>
                  <el-form-item >
                    <v-input v-model="formData.handle_name"></v-input>
                  </el-form-item>
                </div>
              </v-row-col>
            </el-row>
          </el-collapse-transition>
        </div>
      </div>


      <!--      <div-->
      <!--        class="payment-content hand"-->
      <!--        :class="formData.type===1?'check-bload':''"-->
      <!--        @click="handlerClear(1)"-->
      <!--      >-->
      <!--        <div class="check-flex ">-->
      <!--          <span-->
      <!--            class="checked-box hand"-->
      <!--            :class="formData.type==1?'is-checked':''"-->

      <!--          ></span>-->
      <!--        </div>-->
      <!--        <div class="payment-body">-->
      <!--          <div class="flex">-->

      <!--            <span class="title-bold">-->
      <!--              VISA Payment via SMS-->
      <!--            </span>-->
      <!--          </div>-->
      <!--          <el-collapse-transition >-->
      <!--            <el-row-->
      <!--              v-if="formData.type==1"-->
      <!--              :gutter="20"-->
      <!--              justify="space-around"-->
      <!--            >-->
      <!--              <v-row-col>-->
      <!--                <div class="form-item">-->
      <!--                  <span>Last Name</span>-->
      <!--                  <el-form-item prop="last_name">-->
      <!--                    <v-input-->
      <!--                      v-model="formData.last_name"-->
      <!--                    ></v-input>-->
      <!--                  </el-form-item>-->
      <!--                </div>-->
      <!--              </v-row-col>-->
      <!--              <v-row-col>-->
      <!--                <div class="form-item">-->
      <!--                  <span>First Name</span>-->
      <!--                  <el-form-item class="first_name">-->
      <!--                    <v-input-->
      <!--                      v-model="formData.first_name"-->
      <!--                    ></v-input>-->
      <!--                  </el-form-item>-->
      <!--                </div>-->
      <!--              </v-row-col>-->
      <!--              <v-row-col>-->
      <!--                <div class="form-item">-->
      <!--                  <span>Mobile Number</span>-->
      <!--                  <el-form-item prop="mobile_number">-->
      <!--                    <v-input-->
      <!--                      v-model="formData.mobile_number"-->
      <!--                    ></v-input>-->
      <!--                  </el-form-item>-->
      <!--                </div>-->
      <!--              </v-row-col>-->
      <!--              <v-row-col>-->
      <!--                <div class="form-item">-->
      <!--                  <span>Amount Received</span>-->
      <!--                  <el-form-item prop="amount_received">-->
      <!--                    <v-input-->
      <!--                      v-model="formData.amount_received"-->
      <!--                      :disabled="true"-->
      <!--                      amount_received-->
      <!--                      prepend="HKD"></v-input>-->
      <!--                  </el-form-item>-->
      <!--                </div>-->
      <!--              </v-row-col>-->
      <!--            </el-row>-->
      <!--          </el-collapse-transition>-->
      <!--        </div>-->
      <!--      </div>-->
    </el-form>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {computedQuotation,approvePayment } from '@api/order'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'selectPayment',
  mixins: [MixinOptions,MixinRules],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    isPayment:{
      type: Boolean,
      default: false
    },
    isUpdatePayment:{
      type: Boolean,
      default: false
    },
    isLock:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      oldFormDataLock: -1,
      formData:{
        type:2 ,//type 1-visa 2-cheque 3-cash 4-atm
        first_name:'',
        last_name:'',
        mobile_number:'',
        amount_received:'',
        handle_name:'',
        date:'',
        cheque_no:'',
        cheque_file:'',
        account_no:'',
        bank_no_id:'',
        receipt_file:'',
        receipt_file_id:'',
        cheque_file_id:'',
        cheque_file_url:'',
        receipt_file_url:'',
        receipt_client_name:'',
        cheque_client_name:''
      }
    }
  },
  computed:{
    isChecked(){
      let {premium} = this.form
      return premium.amount_received?true:false
    },
    isAble(){
      return !this.formData.date
    }
  },
  watch:{
    form:{
      deep:true,
      immediate:true,
      handler(val){
        if(this.oldFormDataLock!==this.formDataLock){
          this.oldFormDataLock = this.formDataLock
          let {payment={}} = val
          Object.keys(this.formData).some(key => {
            if (key in payment) {
              this.formData[key] = payment[key]
            }
          })
          let {cheque_file,receipt_file} = payment||{}
          if(cheque_file){
            this.formData.cheque_file_id = cheque_file.file_id
            this.formData.cheque_file_url = cheque_file.url
            this.formData.cheque_client_name = cheque_file.client_name
          }
          if(receipt_file){
            this.formData.receipt_file_id = receipt_file.file_id
            this.formData.receipt_file_url = receipt_file.url
            this.formData.receipt_client_name = receipt_file.client_name
          }
          if(!payment.amount_received)
          {
            let {premium } = val
            if(premium)
              this.$set(this.formData,'amount_received', premium.amount_received||0)
          }
        }
      }
    },
    isLock:{
      immediate:true,
      handler(val){
        console.log('val',val)
        if(val){
          this.formData.type = -1
        }
      }
    }
  },
  methods:{
    isDisable(name){
      let {referral} = this.form
      if(!referral)
        return  false
      else{
        return true
      }
    },
    submitForm(formName){
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            payment :this.formData
          }
          this.$emit('getFormData', formName, data)
        }
      })
    },
    handlerClear(type){
      event.stopPropagation()
      if(this.isPayment)return
      if(this.isLock) return
      this.formData.type = type
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { type, index, idKey, nameKey, urlKey,client_name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, '')
      this.$set(this.formData, client_name, '')
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$refs['form'].clearValidate([idKey])
    },
    hanlderProve(){
      this.$emit('handlerApprove')
      // this.network().approvePayment()
    },
    network() {
      return {
        approvePayment:async ()=>{
          let orderNo = {
            order_no:this.$route.query.order_no
          }
          let { data } = await approvePayment(orderNo)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .select-payment{
      .title-bold{
        font-weight: 500;
        font-size: 16px;
      }
    .is-payment{
      background: #F5F7FA!important;
      border: 1px solid #DCDFE6;
      .checked-box{
        background: #F5F7FA!important;
      }
      .payment-body{
        margin-bottom: 0!important;
      }
    }

    .payment-content{
      display: block;
      background: #fff;
      margin-bottom: 20px;
      .check-flex{
        height: 14px;
        /* text-align: right; */
        justify-content: flex-end;
        display: flex;
        .checked-box{
          //display: block;
          border-radius: 0;
        }
        .is-checked{
          background: #076081;
          border-color: #076081;
          &::after{
            transform: rotate(45deg) scaleY(1);
          }
        }

      }
      &.check-bload{
        border: 2px solid #076081;
        //border-color: #076081;
      }
      .payment-body{
      border-radius: 8px;
      margin-bottom: 20px;

      display: block;
      padding:0px 10px 10px;
      .flex{
        justify-content: space-between;
        align-items: center;
        span{
          color: #000;
        }
      }
      .el-row{
        margin-top: 10px;
      }
      }
    }

  }

</style>
